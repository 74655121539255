/* Basic Reset */
:root {
    --link-color-light: #c00056;
    /* Blue for light theme */
    --link-color-dark: #4da6ff;
    /* Lighter blue for dark theme */
    --background-color-light: #ffffff;
    /* White background */
    --background-color-dark: #1a1a1a;
    /* Dark background */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

/* Base styles for <a> tags */
a {
    color: var(--link-color-light);
    /* Default to light theme color */
    transition: color 0.3s ease;
    /* Smooth transition for color change */
    text-decoration: none;
    /* Optional: Removes underline */
}

header {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

nav {
    flex: 1;
}

nav ul {
    display: flex;
    justify-content: flex-end;
    list-style: none;
}

nav ul li {
    margin: 0 10px;
}

nav a {
    text-decoration: none;
    color: #333;
}

.resume-link {
    margin-left: auto;
}

main {
    width: 100%;
    max-width: 960px;
    padding: 20px;
    flex-grow: 1
}

footer {
    width: 100%;
    max-width: 960px;
    text-align: center;
    padding: 20px;
    background-color: #f4f4f4;
    margin-top: auto;
}

/* Button Styles */
.button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Spacing for headings */
h2,
h4 {
    color: #444;
    margin-top: 0;
    margin-bottom: 10px;
}

/* Spacing for paragraphs */
p {
    margin-top: 0;
    margin-bottom: 10px;
}

/* Styling for lists */
ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 20px;
    /* for indenting list items */
}

ul li {
    margin-bottom: 5px;
}

/* Dark Theme Styles */
.dark-mode {
    background-color: #333;
    color: #f4f4f4;
}

.dark-mode header,
.dark-mode footer {
    background-color: #222;
}

.dark-mode nav a,
.dark-mode h2,
.dark-mode footer {
    color: #f4f4f4;
}

.dark-mode .button {
    background-color: #f4f4f4;
    color: #333;
}